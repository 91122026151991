<template>
  <div v-if="selectedClass" class="overview-class-container">
    <div class="class-mission">
      <div class="top-header">
        <div class="left-title-section">
          <h1 class="page-section-title">
            {{ $t("OVERVIEW.WELCOME_CLASS")
            }}<i v-if="selectedClass.id !== -1"
              >{{ " " + selectedClass.name }}!</i
            >
            &#x1F389;
          </h1>
          <p class="subtitle">{{ $t(leftSubtitle) }}</p>
          <DropdownSingle
            :name="$t('OVERVIEW.SELECT_CLASS')"
            :options="myClasses"
            :onselect="selectedNewClass"
            :start-open="selectClassesDropdownOpen"
            :selected-element="selectedClass"
          />
        </div>
        <div class="right-title-section">
          <div class="right-title-section-top">
            <div v-if="weekActive" class="green-dot"></div>
            <h2 class="page-section-title">{{ $t(weekTitle) }}</h2>
          </div>
          <div
            v-if="weekActive || weekSubtitle"
            class="right-title-section-bottom"
          >
              <BasicButton
              v-if="weekActive"
              class="blue-button"
              :button-text="$t('OVERVIEW.PLAY_DEMO_MISSIONS')"
              @click="openDemoMissionModal"
              />
              <BasicButton
              v-if="weekActive"
              class="blue-button"
              :button-text="$t('OVERVIEW.SEE_MISSIONS')"
              @click="navigateToMissions"
              />

            <p v-if="weekSubtitle" class="subtitle">
              {{ $t(weekSubtitle) }}
            </p>
          </div>
        </div>
      </div>

      <OverviewMission
        v-if="globalWeekData"
        :week-status="
          gameOverview?.weekStatus
            ? gameOverview.weekStatus
            : globalWeekData.status
        "
        :mission-data="gameOverview?.nextUnlockedMission"
        :current-day="
          gameOverview?.nextUnlockedMission
            ? gameOverview?.nextUnlockedMission.dayOfMM
            : null
        "
        :start-time="Date.parse(globalWeekData.startDate) / 1000"
        :end-position="gameOverview?.endPosition"
        :bits-collected="
          gameOverview?.ranking ? gameOverview?.ranking.bitsEarned : 0
        "
        :class-id="selectedClass.id"
        :class-name="selectedClass.name"
        :all-missions-completed="gameOverview?.allMissionsCompleted"
      />
    </div>

    <div v-if="preWeek" class="side-and-bottom-padding">
      <PreWeekDemo :class-id="selectedClass.id" />
    </div>

    <div v-if="!preWeek">
      <template v-if="weekActive && gameOverview?.ranking">
        <CurrentWeek
          :current-amount-of-bits="gameOverview.ranking.bitsEarned"
          :current-rank="currentRank"
        />
      </template>

      <!-- Media Quiz -->
      <div
        v-if="weekActive && gameOverview?.ranking"
        class="media-quiz-extra-section"
      >

      <!-- Extra games image related -->
      <div class="extra-game-image-wrapper">
        <div class="extra-game-image-container">
          <img class="image" src="@/assets/img/img_extras_cropped.png" alt="extra-games" />
          <div class="extra-game-image">
            <h2 class="title">{{ $t('OVERVIEW.EXTRA_GAMES_BLOCK.TITLE') }}</h2>
            <p class="description">{{ $t('OVERVIEW.EXTRA_GAMES_BLOCK.DESCRIPTION') }}</p>
            <div class="button-wrapper">
              <BasicButton
              :button-text="$t('OVERVIEW.EXTRA_GAMES_BLOCK.BUTTON')"
              @click="navigateToExtraGames"
              />
            </div>
          </div>
        </div>
        
        <MediaQuizProgress
        :mediaquiz-progress="gameOverview.extraBits.mediaquiz.progress"
        :amount-of-times-played="gameOverview.extraBits.mediaquiz.timesPlayed"
        :class-id="selectedClass.id"
        :current-day="globalWeekData.currentDay"
        :bits-per-day="gameOverview.extraBits.mediaquiz.bitsPerDay"
        :max-bits="gameOverview.extraBits.mediaquiz.maxBitsEarnable"
        :start-date="globalWeekData.startDate"
        :end-date="globalWeekData.endDate"
        :new-bits="newBits"
        />
        </div>
      </div>

      <!-- Extra bits section -->
      <div
        v-if="weekActive && gameOverview?.extraBits"
        class="extra-bits-section"
      >
        <div class="extra-bits-section-bottom">
          <div class="extra-bits-section-top">
          <div class="section-top-inner">
            <div class="header">
              <div class="icon-container">
                <img src="@/assets/icons/joystick_white.svg" alt="joystick" class="image"/>
              </div>
              <h2 class="title">{{ $t("OVERVIEW.EXTRA_BITS_TITLE") }}</h2>
            </div>

            <DailyBitsEarned
              :current-day="globalWeekData.currentDay"
              :bits-per-day="gameOverview.extraBits.extraGames.bitsPerDay"
              :max-bits="gameOverview.extraBits.extraGames.maxBitsEarnable"
              :start-date="globalWeekData.startDate"
              :end-date="globalWeekData.endDate"
              :new-bits="newBits"
              progressBar="bar"
            />
          </div>
        </div>
          <InfoGameBox 
            :extra-games="gameOverview.extraBits.extraGames.content"
            :current-day="globalWeekData.currentDay"
          />
          <div class="extra-missions-wrapper">
            <!-- Extra missions left column -->
            <div class="extra-missions">
              <div v-for="(mission, index) in gameOverview.extraBits.extraGames.content" :key="index" class="extra-mission-box">
                <Mission
                  v-if="index % 2 === 0"
                  :key="index"
                  :title="mission.name"
                  :times-played="mission.timesPlayed"
                  link-text="Bekijk in MediaMasters Extra"
                  :link-url="getExtraGameUrl(mission.id)"
                  :icon="mission.image ? mission.image : null"
                  :reward-amount="mission.bitsEarned"
                  :description="mission.description"
                  :current-day="globalWeekData.currentDay"
                  :start-date="globalWeekData.startDate"
                  :end-date="globalWeekData.endDate"
                  :bits-per-day="mission.bitsPerDay"
                />
              </div>
          </div>
          <!-- Extra missions right column -->
          <div class="extra-missions">
            <div v-for="(mission, index) in gameOverview.extraBits.extraGames.content" :key="index" class="extra-mission-box">
              <Mission
                v-if="index % 2 !== 0"
                :key="index"
                :title="mission.name"
                :times-played="mission.timesPlayed"
                link-text="Bekijk in MediaMasters Extra"
                :link-url="getExtraGameUrl(mission.id)"
                :icon="mission.image ? mission.image : null"
                :reward-amount="mission.bitsEarned"
                :description="mission.description"
                :current-day="globalWeekData.currentDay"
                :start-date="globalWeekData.startDate"
                :end-date="globalWeekData.endDate"
                :bits-per-day="mission.bitsPerDay"
                />
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <!--<PreWeekDemo v-else-if="preWeek" :class-id="selectedClass.id"/>-->
    </div>
    <PostWeekResults
      v-if="isPostWeek"
      class="side-and-bottom-padding"
      :rank="currentRank"
      :show-ranking-link="shouldShowRankingPostWeek"
      :bits-amount="gameOverview?.ranking.bitsEarned"
      :class-id="selectedClass.id"
    />
  </div>
  <BasicModal />
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";

import PreWeekDemo from "@/components/partials/overview/PreWeekDemo";
import OverviewMission from "@/components/elements/missions/OverviewMission";
import PostWeekResults from "@/components/partials/overview/PostWeekResults";
import CurrentWeek from "@/components/partials/overview/CurrentWeek";
import Mission from "@/components/elements/missions/Mission";
import InfoGameBox from "@/components/elements/missions/InfoGameBox";
import DropdownSingle from "@/components/elements/missions/DropdownSingle";
// import ExtraBitsProgressBar from '@/components/elements/missions/ExtraBitsProgressBar'
import MediaQuizProgress from "@/components/elements/missions/MediaQuizProgress";
import {
  GET_GAME_OVERVIEW,
  GET_SCHOOL_CLASSES,
} from "@/store/modules/teacherPortal/actions";
import {
  ACTIVE_WEEK_STATUS,
  PASSED_PRE_RANKING_STATUS,
  PASSED_WEEK_STATUS,
  UPCOMING_WEEK_STATUS,
} from "@/utils/data/weekStatus";
import { ROUTE_NAMES_TEACHER_PORTAL } from "@/router/modules/teacherPortal";
import { SET_SELECTED_CLASS } from "@/store/modules/teacherPortal/mutations";
import BasicModal from "@/components/elements/basicModal/BasicModal";
import {
  RESET_BASIC_MODAL_DATA,
  SET_BASIC_MODAL_DATA,
} from "@/store/mutations";
import { BASIC_MODAL_CONTENT } from "@/models/basicModal/BasicModalContent";
import { MODAL_NAMES } from "@/utils/helpers/basicModal/ModalController";
// import DiscoverMMExtra from '@/components/partials/overview/DiscoverMMExtra'
import { classRanks } from "@/utils/data/classRanks";
import { rankData } from "@/utils/data/rankData";
import { mergeArrayObjects } from "@/utils/helpers/utils";
import DailyBitsEarned from "@/components/elements/missions/DailyBitsEarned.vue";
import { watch } from "vue";
import BasicButton from '@/components/elements/basic/BasicButton'

export const PLAY_WITHOUT_CLASS = {
  id: -1,
  name: "Speel zonder klas",
};

export default {
  name: "Overview",
  components: {
    PreWeekDemo,
    OverviewMission,
    PostWeekResults,
    // ExtraBitsProgressBar,
    CurrentWeek,
    DropdownSingle,
    MediaQuizProgress,
    Mission,
    BasicModal,
    // DiscoverMMExtra,
    DailyBitsEarned,
    InfoGameBox,
    BasicButton
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const globalWeekData = computed(() => store.getters.getWeekData);

    const isPostWeek = computed(
      () =>
        globalWeekData.value &&
        (globalWeekData.value.status === PASSED_WEEK_STATUS ||
          globalWeekData.value.status === PASSED_PRE_RANKING_STATUS)
    );
    const shouldShowRankingPostWeek = computed(() => {
      const weekData =
        globalWeekData.value &&
        globalWeekData.value.status === PASSED_WEEK_STATUS;
      return weekData && selectedClass.value.id !== -1;
    });
    const weekActive = computed(
      () =>
        globalWeekData.value &&
        globalWeekData.value.status === ACTIVE_WEEK_STATUS
    );
    const preWeek = computed(
      () =>
        globalWeekData.value &&
        globalWeekData.value.status === UPCOMING_WEEK_STATUS
    );

    const profile = computed(() => store.getters.getTeachersProfile);
    const newBits = computed(() => {
      return route.query?.bitsEarned ? route.query.bitsEarned : 0;
    });

    const classes = computed(() => store.getters.getSchoolClasses);

    const selectedClass = computed(() => store.getters.getSelectedClass);
    const myClasses = computed(() => {
      const selectedClasses = classes.value
        ? classes.value.filter((aClass) =>
            aClass.teachers.some(
              (teacher) => teacher.id === profile.value.teacherId
            )
          )
        : [];
      selectedClasses.push(PLAY_WITHOUT_CLASS);
      return selectedClasses;
    });

    const gameOverview = computed(() => store.getters.getGameOverview);
    const playingWithoutClass = computed(() => selectedClass.value?.id === -1);
    const missionOverviewLink = computed(() => {
      const classId = selectedClass.value ? selectedClass.value.id : -1;
      return `${process.env.VUE_APP_ROOT_GAME}/selecteer-missie/${classId}`;
    });

    const currentDay = computed(() =>
      gameOverview.value && gameOverview.value.nextUnlockedMission
        ? gameOverview.value.nextUnlockedMission.dayOfMM
        : null
    );
    const allMissionsCompleted = computed(() =>
      gameOverview.value ? gameOverview.value.allMissionsCompleted : null
    );

    const weekTitle = computed(() => {
      if (preWeek.value) {
        return "OVERVIEW.MISSION.PRE_WEEK.TITLE";
      } else if (weekActive.value && currentDay.value === 1) {
        return "OVERVIEW.MISSION.DURING_WEEK_FIRST_MISSION.TITLE";
      } else if (weekActive.value && allMissionsCompleted.value) {
        return "OVERVIEW.MISSION.DURING_WEEK_USER_FINISHED_ALL_MISSION.TITLE";
      } else if (weekActive.value && currentDay.value > 1) {
        return "OVERVIEW.MISSION.DURING_WEEK_OTHER_MISSIONS.TITLE";
      } else if (isPostWeek.value) {
        return "OVERVIEW.MISSION.POST_WEEK_RESULTS.TITLE";
      } else {
        return "";
      }
    });

    const weekSubtitle = computed(() => {
      if (weekActive.value) {
        return "";
      } else if (isPostWeek.value) {
        return "OVERVIEW.MISSION.WEEK_ENDED";
      } else {
        return "OVERVIEW.MISSION.WEEK_ALMOST_STARTS";
      }
    });

    const leftSubtitle = computed(() => {
      if (weekActive.value && selectedClass.value.id === -1) {
        return "OVERVIEW.NO_CLASS_INFO";
      } else {
        return "";
      }
    });

    const selectClassesDropdownOpen = ref(false);
    store.dispatch(GET_SCHOOL_CLASSES, {}).then(() => {
      if (route.params.classId) {
        const foundClass = myClasses.value.find(
          (aClass) => aClass.id === Number.parseInt(route.params.classId)
        );
        foundClass
          ? selectNewClass(foundClass)
          : myClasses.value.length > 0
          ? selectNewClass(myClasses.value[0])
          : promptNoClassSelected();
      } else if (!selectedClass.value) {
        myClasses.value.length > 0
          ? selectNewClass(myClasses.value[0])
          : promptNoClassSelected();
      }
      if (selectedClass.value) {
        store.dispatch(GET_GAME_OVERVIEW, selectedClass.value.id);
      }
    });

    selectClassesDropdownOpen.value = route.query["choose-class"] === "true";

    function selectNewClass(newClass) {
      if (route.meta.platform === "professional") {
        return;
      }

      if (newClass.id !== -1) {
        // selectedClass set earlier if 'no class' selected. This is because dom elements are unable to
        // retrieve gameOverview properties when selected class is set after the 'get-game-overview' call
        store.commit(SET_SELECTED_CLASS, newClass);
      }

      if (!newClass) {
        router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.MY_CLASSES });
      } else {
        store.dispatch(GET_GAME_OVERVIEW, newClass.id).then((res) => {
          store.commit(SET_SELECTED_CLASS, newClass);
          router.push({
            to: ROUTE_NAMES_TEACHER_PORTAL.OVERVIEW,
            params: { classId: newClass.id },
          });
        });
      }
    }

    const mediamastersExtraRoute = `${process.env.VUE_APP_ROOT_GAME}/extra-games`;
    const currentRank = computed(() =>
      gameOverview.value && gameOverview.value.ranking
        ? findRank(gameOverview.value.ranking.currentRankId)
        : null
    );
    const nextRank = computed(() =>
      gameOverview.value && gameOverview.value.ranking
        ? findRank(gameOverview.value.ranking.currentRankId + 1)
        : null
    );

    const ranks = mergeArrayObjects(classRanks, rankData);

    function findRank(id) {
      return ranks.find((rank) => (gameOverview.value ? rank.id === id : null));
    }

    function promptNoClassSelected() {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.PLAY_WITHOUT_CLASS,
          cancelCallback: () => {
            selectNewClass(PLAY_WITHOUT_CLASS);
            selectClassesDropdownOpen.value = true;
          },
          executeCallback: () => {
            store.commit(RESET_BASIC_MODAL_DATA);
            selectNewClass(PLAY_WITHOUT_CLASS);
          },
        },
        activeModal: MODAL_NAMES.SELECT_DIFFERENT_CLASS,
      };

      store.commit(SET_BASIC_MODAL_DATA, payload);
    }

    function selectedNewClass(choice) {
      if (selectedClass.value && choice.id === selectedClass.value.id) {
        return;
      }

      let content;
      let activeModal;
      if (choice.id === -1) {
        content = BASIC_MODAL_CONTENT.PLAY_WITHOUT_CLASS;
        activeModal = MODAL_NAMES.PLAY_WITHOUT_CLASS;
      } else {
        content = BASIC_MODAL_CONTENT.SELECT_DIFFERENT_CLASS(choice.name);
        activeModal = MODAL_NAMES.SELECT_DIFFERENT_CLASS;
      }
      const payload = {
        modalOptions: {
          modalContent: content,
          cancelCallback: null,
          executeCallback: () => {
            store.commit(RESET_BASIC_MODAL_DATA);
            selectNewClass(choice);
          },
        },
        activeModal: activeModal,
      };
      store.commit(SET_BASIC_MODAL_DATA, payload);
    }

    function getExtraGameUrl(missionId) {
      let route = mediamastersExtraRoute + "?";
      if (!playingWithoutClass.value) {
        route += `class-id=${selectedClass.value.id}&`;
      } else {
        route += "no-class=true";
      }
      route += `game-id=${missionId}`;
      return route;
    }

    function openDemoMissionModal() {
     //open a modal DEMO_MISSION_WARNING
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.DEMO_MISSION_WARNING,
          cancelCallback: null,
          executeCallback: () => {
            store.commit(RESET_BASIC_MODAL_DATA);
            window.open(mediaMastersDemoMissionsRoute, "_blank");
          },
        },
        activeModal: MODAL_NAMES.DEMO_MISSION_WARNING,
      };
      store.commit(SET_BASIC_MODAL_DATA, payload);
    }

    function navigateToExtraGames() {
      window.open(mediamastersExtraRoute, "_blank");
    }

    const mediaMastersDemoMissionsRoute = `${process.env.VUE_APP_ROOT_GAME}/demo`;
    function navigateToMissions() {
      window.open(missionOverviewLink.value, "_blank");
    }

    return {
      myClasses,
      selectedNewClass,
      selectedClass,
      gameOverview,
      mediamastersExtraRoute,
      globalWeekData,
      currentRank,
      nextRank,
      getExtraGameUrl,
      missionOverviewLink,
      playingWithoutClass,
      store,
      newBits,
      selectClassesDropdownOpen,
      weekSubtitle,

      UPCOMING_WEEK_STATUS,
      ACTIVE_WEEK_STATUS,
      PASSED_PRE_RANKING_STATUS,
      PASSED_WEEK_STATUS,

      weekTitle,
      preWeek,
      weekActive,
      isPostWeek,
      shouldShowRankingPostWeek,
      leftSubtitle,

      openDemoMissionModal,
      navigateToExtraGames,
      navigateToMissions
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";
// $right-bar-width: rem(0);

.right-title-section {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .right-title-section-top {
    display: flex;
    align-items: center;

    .green-dot {
      height: 12px;
      width: 12px;
      background-color: #85ff87;
      border-radius: 50%;
      margin-right: 12px;
      flex-shrink: 0;
    }
  }

  .right-title-section-bottom {
    margin-top: 10px;
    display: flex;
    gap: rem(15);

    .blue-button {
      background-color: #0e4e95;
      color: white;
      padding: rem(11) rem(24);
    }
  }
}

.overview-class-container {
  // max-width: 1400px;
  // margin: auto;

  .current-week {
    margin-bottom: rem(44);
  }

  .class-mission {
    padding: rem(32) rem(49);
  }

  .media-quiz-extra-section {
    padding: 0 rem(49);
    width: 100%;
  }

  .extra-bits-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 rem(49) rem(49) rem(49);

    .extra-bits-section-top {
      width: 100%;
      z-index: 2;
      padding-bottom: rem(32);

      .section-top-inner {
        text-align: left;
        // todo small daily blocks
        max-width: 100%;

        .header{
          display: flex;
          align-items: center;
          gap: rem(10);
          margin-bottom: rem(20);

          .icon-container{
            background-color: var(--green_light);
            padding: rem(10);
            border-radius: rem(10);
          }

          .image{
            height: rem(30);
            width: rem(30);
          }
          .title{
            text-transform: uppercase;
          }
        }
      }
    }

    .extra-bits-section-bottom {
      position: relative;
      background-color: rgb(255, 255, 255);
      border-radius: 0 0 rem(20) rem(20);
      width: 100%;
      padding: rem(32) rem(49) rem(80) rem(49);
    }

    h2 {
      margin-bottom: rem(10);
    }

    .extra-missions-wrapper{
      display: flex;
      gap: rem(20);

      .extra-missions {
        margin-top: rem(20);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: start;
        width: 50%;
      }

      .extra-mission-box > .mission {
          margin-top: rem(20);
      }
    }
  }

  .extra-game-image-wrapper{
    margin-bottom: rem(12);
    padding: rem(32) rem(32) rem(24) rem(32);
    background-color: rgb(255, 255, 255);
    border-radius: rem(20) rem(20) 0 0;

    .extra-game-image-container{
      background-color: rgb(255, 255, 255);
      border-radius: rem(20);
      position: relative;
      overflow: hidden;
      margin-bottom: rem(49);

      .image{
        position: absolute;
        right: 0;
        top: 0;
      }
      
      .extra-game-image{
        color: rgb(255, 255, 255);
        background: linear-gradient(90deg,var(--blue_light_05) 0%, var(--purple_light) 40%, var(--pink_dark) 100%);
        padding: rem(24);
        border-radius: rem(15);
        display: flex;
        flex-direction: column;
        gap: rem(10);
    
      .button-wrapper{
        margin: rem(24) 0 rem(12) 0;
      }
      
      .description{
        max-width: rem(650);
      }
     }
    }
  }
}

.side-and-bottom-padding {
  padding: 0 rem(49) rem(32) rem(49);
}

.top-header {
  margin-bottom: rem(25);
  margin-top: rem(11);
  background-color: rgb(255, 255, 255);
  border-radius: rem(20);
  padding: rem(30);
  display: flex;
  justify-content: space-between;
  gap: rem(16);

  .subtitle {
    margin-top: rem(8);
    font-size: rem(14);
    color: var(--purple_light);
  }

  :deep(.dropdown-container) {
    font-size: 20px;
    margin-top: 12px;
  }
}

.section-text {
  margin-bottom: rem(42);
}

.right-sidebar-day {
  border-radius: 20px;
  background: white linear-gradient(180deg, #3240a2 0%, #1c287d 100%);
  text-align: center;
  height: rem(500);
  position: relative;
  margin-bottom: rem(50);
  margin-top: rem(20);

  .day-text {
    background-color: #212d84;
    border-radius: 17.5px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(24);
    padding: rem(8);
  }

  .middle-text {
    // top: 70%;
    position: relative;
    top: rem(300);
  }

  .bottom-button {
    position: relative;
    top: rem(60);
  }
}

.day-streak {
  display: flex;
  gap: rem(12);
  margin-top: rem(15);
}

.completed-day {
  display: inline;
  padding: rem(6) rem(6);
  background-color: gold;
  border-radius: rem(12);
}

.day {
  padding: rem(35) rem(6);
  background-color: gold;
  border-radius: rem(12);
}

.completed {
  background-color: gold;
}

.uncompleted {
  background-color: purple;
}

.uncompleted-day {
  display: inline;
  padding: rem(20) rem(20) rem(50);
  background-color: purple;
  margin: rem(6);
  border-radius: rem(12);
}

.streak-bar {
  position: relative;
  top: rem(100);
  padding: rem(2) rem(20) rem(2) rem(20);
  border-radius: rem(50);
}
</style>
